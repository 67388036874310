<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="register_wrapper">
          <div class="register_header_wrapper">
              <div class="register_header">
                <img src="@/assets/img/bici/logo.png" width="40"/>
                <div class="register_title">
                  <small>China1 | HCCP</small>
                  <span>{{ title }}</span>
                </div>
              </div>
          </div>
          <div class="invalid">
            <div class="invalid-img"><img src="@/assets/img/invalid.png"/></div> 
            <div class="invalid-text">Invalid page range.<br>页面范围无效</div>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_invalid.styl'

export default {
  name: 'InvalidAccess',
  mixins: [
    
  ],
  data: () => ({
    messagered:'',
    Email: '',
    messageEN: '',
    messageCH: '',
    title: 'Invalid Page',
    

  }),
  
  created() {
    
  },
  methods: {
  

  }
}
</script>